// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-single-menu-page-tsx": () => import("./../src/templates/single-menu-page.tsx" /* webpackChunkName: "component---src-templates-single-menu-page-tsx" */),
  "component---src-templates-menu-page-tsx": () => import("./../src/templates/menu-page.tsx" /* webpackChunkName: "component---src-templates-menu-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

